import * as THREE from 'three';

export function GetDevice() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	if (window.innerWidth > 1024 || window.innerHeight > 1024) return undefined;
	else if (/windows phone/i.test(userAgent)) { return "windows"; }
	else if (/android/i.test(userAgent)) { return "android"; }
	else if (/iPhone|iPod|iPad/.test(userAgent) && !window.MSStream) { return "ipone"; }
	// if (/iPad/.test(userAgent) && !window.MSStream) { return "ipad"; }
	else return undefined;
}

export function isMobile() {
	var check = 'Web';
	(function(a){
	if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
		check = 'Mobile';
	})(navigator.userAgent||navigator.vendor||window.opera);
	return check;
};

export const minSizeBuild = {x:4, z:25};

export const houseH = 4, planeSize = 200, corridorWidth = 0.7, inch2m = 0.0254, sumpScl = 0.254;

export const sizeNFT = {w:0.8, d:3, h:1.8},
	sizeDwc = {w:1, h:0.35, d:4.5},
	// sizeDwc = {w:4, h:0.35, d:6},
	sizeFish = {w:2.5, h:1.7, d:2.5},
	sizeSump = {w:1.6, h:5, d:1.6},
	sizeMbgb = {w:1, d:3, h:0.85},
	sizeFilter = {w:2, h:1.3, d:2};

const wSize = {width:window.innerWidth, height:window.innerHeight};
const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

export function GetRaycaster(camera, e, arr) {
	pointer.x = ( e.clientX / wSize.width ) * 2 - 1;
	pointer.y = - ( e.clientY / wSize.height ) * 2 + 1;
	raycaster.setFromCamera( pointer, camera );
	return raycaster.intersectObjects( arr )[0];
}

export function GetRoundNum(val, size = 1) {
	if (!val) return 0;
	return Math.round(val * Math.pow(10, size)) / Math.pow(10, size);
}

export function GetIdStr(strLength = 10) {
	const oriStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890-=!@#$%^&*()_+';
	var idStr = '';
	for (let i = 0; i < strLength; i++) {
		idStr += oriStr[Math.floor(oriStr.length * Math.random())]
	}
	return idStr;
}

export function GetManModels(modelArr, type, isSump) {
	const objectArr = [];
	modelArr.forEach(model => {
		const {needCount, count, key, size} = model;
		if (key==='sump') return;
		if (!isSump && type==='opt' && key==='dwc') return;
		if (type==='man') {
			const realCount = key==='dwc'?size:count;
			if (needCount > realCount) {
				const unit = key==='dwc'?model.unit:'piece';
				objectArr.push({...model, addCount:'+ '+(needCount-realCount)+' '+unit});
			}
		} else {
			// if (key!=='fish' && key!=='filter' && needCount <= count)
				objectArr.push({...model});
		}
	});
	return objectArr;
}

export function GetTotalValue(needArr, key='price') {
	var totalValue = 0;
	needArr.forEach(item => {
		totalValue += item[key] || 0;
	});
	return totalValue;
}

export function GetFloorPos(intersect) {
	const {point} = intersect;
	return {x:GetRoundNum(point.x), z:GetRoundNum(point.z)}
}